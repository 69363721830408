import axios from "axios";
import { useEffect, useState } from "react";
import appConfig from "../config.json";
import "../views/certificates.css";
import {
  Space,
  Table,
  Button,
  Modal,
  Input,
  Form,
  message,
  Popconfirm,
  Select,
  DatePicker,
  Spin,
} from "antd";
import "../bootstrap-grid.css";
import { UserAddOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Header from "../components/Header";
import Loader from "../components/Loader";
import {useNavigate} from "react-router-dom";

function Certificates() {
    let navigate = useNavigate();
  const [certificateData, setCertificateData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNewOpen, setIsModalNewOpen] = useState(false);
  const [certificateToEdit, setCertificateToEdit] = useState(null);
  const [photoFileContent, setPhotoFileContent] = useState(null);
  const [isActiveLoader, setIsActiveLoader] = useState(false);

  const formatos = [
    {
      label: "Versión 1",
      value: 1,
    },
    {
      label: "Versión 2",
      value: 2,
    },
    {
      label: "Versión 3 (2024)",
      value: 3,
    },
    {
      label: "Versión 4 (2024)",
      value: 4,
    },
    {
      label: "Versión 5 (2025)",
      value: 5,
    },
  ]

  const columns = [
    {
      title: "Numero PIC",
      dataIndex: "numero_pic",
      key: "numero_pic",
      width: "120px",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "RFC",
      dataIndex: "rfc",
      key: "rfc",
    },
    {
      title: "Enlace QR",
      dataIndex: "enlace_qr",
      key: "enlace_qr",
      render: function (data, record) {
        return (
          <a href={data} target="_blank">
            Ver certificado
          </a>
        );
      },
    },
    {
      title: "Formato",
      dataIndex: "id_formato",
      key: "id_formato",
      width: "120px",
      render: function (data, record) {
        return "Versión " + data;
      },
    },
    {
      title: "Fecha de certificación",
      dataIndex: "fecha_certificacion",
      key: "fecha_certificacion",
    },
    {
      title: "Opciones",
      key: "opciones",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button type="text" onClick={(e) => editModalHandle(record)}>
              Editar
            </Button>
            {record.activo === 1 ? (
              <Popconfirm
                title="¿Está seguro de desactivar a este certificado?"
                description="Una vez desactivado, este certificado dejará de funcionar hasta que se vuelva a activar."
                onConfirm={(e) => {
                  updateActiveCertificate(record.id, 0);
                }}
                onCancel={() => {}}
                okText="Sí, desactivar"
                cancelText="Cancelar"
              >
                <Button type="text" danger>
                  Desactivar
                </Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={(e) => {
                  updateActiveCertificate(record.id, 1);
                }}
                type="text"
              >
                Activar
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const editModalHandle = (event) => {
    getCertificatePhoto(event.numero_pic);
    setCertificateToEdit(event);
    setIsModalOpen(true);
  };

  const newModalHandle = () => {
    setCertificateToEdit(null);
    setPhotoFileContent("");
    setIsModalNewOpen(true);
  };

  const onChangeName = (event) => {
    setCertificateToEdit({ ...certificateToEdit, nombre: event.target.value });
  };

  const onChangeNumeroPic = (event) => {
    setCertificateToEdit({
      ...certificateToEdit,
      numero_pic: event.target.value,
    });
  };
  const onChangeRFC = (event) => {
    setCertificateToEdit({ ...certificateToEdit, rfc: event.target.value });
  };
  const onChangeFoto = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (e) => {
      console.log(e);
      if (e.total > 2097152) {
        message.error("La imagen seleccionada supera los 2 MB de tamaño");
      } else {
        let sbstr = e.target.result.substring(11, 15);
        if (sbstr.includes("jpeg") || sbstr.includes("png")) {
          setCertificateToEdit({
            ...certificateToEdit,
            foto: e.target.result,
          });
          setPhotoFileContent(e.target.result);
        } else {
          message.error("Formato de archivo no admitido");
        }
      }
    };
    setCertificateToEdit({ ...certificateToEdit, foto: event.target.value });
  };
  const onChangeEmpresa = (event) => {
    setCertificateToEdit({ ...certificateToEdit, empresa: event.target.value });
  };
  const onChangeFechaCert = (date, dateString) => {
    setCertificateToEdit({
      ...certificateToEdit,
      fecha_certificacion: dateString,
    });
  };
  const onChangeFormato = (value) => {
    setCertificateToEdit({
      ...certificateToEdit,
      id_formato: value,
    });
  };

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = () => {
    let token = localStorage.getItem("token");
    setIsActiveLoader(true);
    axios
      .get(appConfig.API_URL + "api/app/get-certificates", {
        headers:{
            'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        if (response.data.success) {
          setCertificateData(response.data.data);
        }
        setIsActiveLoader(false);
      });
  };

  const updateActiveCertificate = (id_cert, activeValue) => {
    try {
        let token = localStorage.getItem("token");
      axios
        .post(appConfig.API_URL + "api/app/update-user-active", {
          id_cert: id_cert,
          active_value: activeValue,
        }, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
      })
        .then((response) => {
          if (response.data.success) {
            //setEventsData(response.data.data);
            message.info("Usuario actualizado correctamente");
            getCertificates();
          }
        });
    } catch (error) {}
  };


  const parseDate = (date) => {
    dayjs.extend(customParseFormat);
    return dayjs(date);
  };

  const savecertificateToEdit = () => {
    setIsModalOpen(false);
    let token = localStorage.getItem("token");
    axios
      .post(
        appConfig.API_URL + "api/app/update-certificate",
        certificateToEdit,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          //setEventsData(response.data.data);
          message.info("Certificado actualizado correctamente");
          getCertificates();
        }
      });
  };

  const saveNewCertificate = () => {
    setIsModalNewOpen(false);
    let token = localStorage.getItem("token");
    axios
      .post(appConfig.API_URL + "api/app/add-certificate", certificateToEdit, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          //setEventsData(response.data.data);
          message.info("Certificado registrado correctamente");
          getCertificates();
        }
      });
  };

  const getCertificatePhoto = (numero_pic) => {
    setPhotoFileContent(null);
    let token = localStorage.getItem("token");
    axios
      .post(
        appConfig.API_URL + "api/app/get-certificate-photo",
        {
          numero_pic: numero_pic,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          //setEventsData(response.data.data);
          setPhotoFileContent(response.data.data);
        }
      });
  };

  return (
    <>
      <Header></Header>
      <div className="contenedor">
        <div className="eventosContainer">
          <div className="eventHeader">
            <div className="sectionTitle">Certificados</div>
          </div>
          <Space size="small">
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              onClick={newModalHandle}
              style={{ marginBottom: 10 }}
            >
              Registrar certificado
            </Button>
          </Space>
          <Table
            columns={columns}
            size="small"
            sticky
            dataSource={certificateData}
          />
        </div>
      </div>
      <Modal
        title="Edición de certificado"
        open={isModalOpen}
        onOk={savecertificateToEdit}
        onCancel={(e) => setIsModalOpen(false)}
        width={1000}
        okText="Actualizar certificado"
        cancelText="Cancelar"
      >
        <div className="formContainer">
          <Form layout="vertical">
            <div className="row">
              <div className="col-4">
                <Form.Item label="Numero PIC">
                  <Input
                    placeholder=""
                    onChange={onChangeNumeroPic}
                    value={
                      certificateToEdit != null
                        ? certificateToEdit.numero_pic
                        : ""
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Nombre">
                  <Input
                    placeholder=""
                    onChange={onChangeName}
                    value={
                      certificateToEdit != null ? certificateToEdit.nombre : ""
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="RFC">
                  <Input
                    placeholder=""
                    onChange={onChangeRFC}
                    value={
                      certificateToEdit != null ? certificateToEdit.rfc : ""
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Form.Item label="Empresa">
                  <Input
                    placeholder=""
                    onChange={onChangeEmpresa}
                    value={
                      certificateToEdit != null ? certificateToEdit.empresa : ""
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Formato">
                  <Select
                    style={{
                      width: "90%",
                    }}
                    value={
                      certificateToEdit != null
                        ? certificateToEdit.id_formato
                        : ""
                    }
                    onChange={onChangeFormato}
                    options={formatos}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Fecha de certificación">
                  <DatePicker
                    onChange={onChangeFechaCert}
                    value={
                      certificateToEdit != null
                        ? parseDate(certificateToEdit.fecha_certificacion)
                        : ""
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                {/* <Form.Item label="Fotografía">
                  <Input
                    placeholder=""
                    onChange={onChangeFoto}
                    value={
                      certificateToEdit != null ? certificateToEdit.foto : ""
                    }
                  />
                </Form.Item> */}
                <Form.Item label="Cambiar fotografía actual">
                  <input
                    style={{ width: "135px" }}
                    type="file"
                    onChange={onChangeFoto}
                    accept="image/png, image/jpeg"
                  ></input>
                </Form.Item>
              </div>
              <div className="col-3">
                {photoFileContent != null ? (
                  <img style={{ width: 100 }} src={photoFileContent}></img>
                ) : (
                  <Spin tip="Cargando" size="small"></Spin>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Generar nuevo certificado"
        open={isModalNewOpen}
        onOk={saveNewCertificate}
        onCancel={(e) => setIsModalNewOpen(false)}
        width={1000}
        okText="Registrar certificado"
        cancelText="Cancelar"
      >
        <div className="formContainer">
          <Form layout="vertical">
            <div className="row">
              <div className="col-4">
                <Form.Item label="Numero PIC">
                  <Input
                    placeholder=""
                    onChange={onChangeNumeroPic}
                    value={
                      certificateToEdit != null
                        ? certificateToEdit.numero_pic
                        : ""
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Nombre">
                  <Input
                    placeholder=""
                    onChange={onChangeName}
                    value={
                      certificateToEdit != null ? certificateToEdit.nombre : ""
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="RFC">
                  <Input
                    placeholder=""
                    onChange={onChangeRFC}
                    value={
                      certificateToEdit != null ? certificateToEdit.rfc : ""
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <Form.Item label="Empresa">
                  <Input
                    placeholder=""
                    onChange={onChangeEmpresa}
                    value={
                      certificateToEdit != null ? certificateToEdit.empresa : ""
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Formato">
                  <Select
                    style={{
                      width: "90%",
                    }}
                    value={
                      certificateToEdit != null
                        ? certificateToEdit.id_formato
                        : ""
                    }
                    onChange={onChangeFormato}
                    options={formatos}
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item label="Fecha de certificación">
                  <DatePicker
                    onChange={onChangeFechaCert}
                    value={
                      certificateToEdit != null
                        ? parseDate(certificateToEdit.fecha_certificacion)
                        : ""
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                {/* <Form.Item label="Fotografía">
                  <Input
                    placeholder=""
                    onChange={onChangeFoto}
                    value={
                      certificateToEdit != null ? certificateToEdit.foto : ""
                    }
                  />
                </Form.Item> */}
                <Form.Item label="Cambiar fotografía actual">
                  <input
                    style={{ width: "135px" }}
                    type="file"
                    onChange={onChangeFoto}
                    accept="image/png, image/jpeg"
                  ></input>
                </Form.Item>
              </div>
              <div className="col-3">
                {photoFileContent != null ? (
                  <img style={{ width: 100 }} src={photoFileContent}></img>
                ) : (
                  <Spin tip="Cargando" size="small"></Spin>
                )}
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Loader isActive={isActiveLoader}></Loader>
    </>
  );
}

export default Certificates;
